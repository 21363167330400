import React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';

import {
  ProgramicAdditionalInfo,
  ProgramicDetails,
  ProgramicFAQ,
  ProgramicHeader,
  ProgramicIntro,
  ProgramicPricing,
  ProgramicReviews,
  PageNew,
} from '../../../fragments';

const Programic = () => {
  const messages = useIntlMessages();

  return (
    <PageNew
      title={messages.programic}
      description=""
      headline=""
      wrapper={(props) => <div {...props} />}
    >
      <ProgramicHeader />
      <ProgramicIntro />
      <ProgramicDetails />
      <ProgramicAdditionalInfo />
    </PageNew>
  );
};

export default Programic;
