import * as React from 'react';

import { ToSMobile, PageNew } from '../../../fragments';

const ToSPage = () => {
  return (
    <PageNew title="Kokolingo" headline="Opći uvjeti za mobilnu aplikaciju">
      <ToSMobile />
    </PageNew>
  );
};

export default ToSPage;
