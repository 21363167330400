import * as React from 'react';

import { PrivacyPolicyMobile, PageNew } from '../../../fragments';

const PrivacyPolicyMobilePage = () => {
  return (
    <PageNew
      title="Kokolingo"
      headline="Izjava o privatnosti za mobilnu aplikaciju"
    >
      <PrivacyPolicyMobile />
    </PageNew>
  );
};

export default PrivacyPolicyMobilePage;
