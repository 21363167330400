/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
function importContainer(container) {
  let component;
  try {
    component = require(`./${process.env.GATSBY_LOCALE}/${container}`).default;
  } catch {
    component = require(`./default/${container}`).default;
  }
  return component;
}

const Asha = importContainer('asha');
const Blog = importContainer('blog');
const FAQ = importContainer('faq');
const Home = importContainer('home');
const PrivacyPolicy = importContainer('privacy');
const PrivacyPolicyMobile = importContainer('privacyMobile');
const SpeechTherapyAssessment = importContainer('speechTherapyAssessment');
const Teletherapy = importContainer('teletherapy');
const Academy = importContainer('academy');
const AcademyInstitution = importContainer('academyInstitution');
const ToS = importContainer('tos');
const ToSMobile = importContainer('tosMobile');
const Programic = importContainer('programic');

export {
  Asha,
  Blog,
  FAQ,
  Home,
  PrivacyPolicy,
  PrivacyPolicyMobile,
  SpeechTherapyAssessment,
  Teletherapy,
  Academy,
  AcademyInstitution,
  ToS,
  ToSMobile,
  Programic,
};
